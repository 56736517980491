import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

function getUrlParams(name, str) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  const r = str.substr(1).match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}
const baseUrl = getUrlParams('BaseUrl', window.location.href)
// 本地开发调试注入vConsole
const isDebug = baseUrl ===  'http://psptest.alsome.net.cn/';
// if (isDebug) {
  // new VConsole();
// }
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


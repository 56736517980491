
import { withRouter, Link, Route, HashRouter as Router, Switch } from 'react-router-dom'
import Bundle from './Bundle.jsx'

let PsyTestResultPage = function () {
  return <Bundle load={() => import('./pages/PsyTest/PsyTestResultPage')}>
    {(Com) => <Com />}
  </Bundle>;
};
let ExpertYuYue = function () {
  return <Bundle load={() => import('./pages/Agreement/ExpertYuYue')}>
    {(Com) => <Com />}
  </Bundle>;
};
let HelpPage = function () {
  return <Bundle load={() => import('./pages/Agreement/HelpPage')}>
    {(Com) => <Com />}
  </Bundle>;
};
let Privacy = function () {
  return <Bundle load={() => import('./pages/Agreement/Privacy')}>
    {(Com) => <Com />}
  </Bundle>;
};
let UserMain = function () {
  return <Bundle load={() => import('./pages/Agreement/UserMain')}>
    {(Com) => <Com />}
  </Bundle>;
};
let ZhiQingAgree = function () {
  return <Bundle load={() => import('./pages/Agreement/ZhiQingAgree')}>
    {(Com) => <Com />}
  </Bundle>;
};
let PsyTestLastPage = function () {
  return <Bundle load={() => import('./pages/PsyTest/PsyTestLastPage')}>
    {(Com) => <Com />}
  </Bundle>;
};
let ZhuXiao = function () {
  return <Bundle load={() => import('./pages/Agreement/ZhuXiao')}>
    {(Com) => <Com />}
  </Bundle>;
};
let TribeText = function () {
  return <Bundle load={() => import('./pages/Agreement/TribeText')}>
    {(Com) => <Com />}
  </Bundle>;
};
let VipText = function () {
  return <Bundle load={() => import('./pages/Agreement/VipText')}>
    {(Com) => <Com />}
  </Bundle>;
};
let VipAgainText = function () {
  return <Bundle load={() => import('./pages/Agreement/VipAgainText')}>
    {(Com) => <Com />}
  </Bundle>;
};
let SanFang = function () {
  return <Bundle load={() => import('./pages/Agreement/SanFang')}>
    {(Com) => <Com />}
  </Bundle>;
};
let VideoH5 = function () {
  return <Bundle load={() => import('./pages/Course/VideoH5')}>
    {(Com) => <Com />}
  </Bundle>;
};
let CourseShare = function () {
  return <Bundle load={() => import('./pages/Course/CourseShare')}>
    {(Com) => <Com />}
  </Bundle>;
};
let postShare = function () {
  return <Bundle load={() => import('./pages/post/postShare')}>
    {(Com) => <Com />}
  </Bundle>;
};
let EAPXjResult = function () {
  return <Bundle load={() => import('./pages/EAPXjResult/PsyTestResultPage')}>
    {(Com) => <Com />}
  </Bundle>;
};
let EAPXjLastResult = function () {
  return <Bundle load={() => import('./pages/EAPXjResult/PsyTestLastPage')}>
    {(Com) => <Com />}
  </Bundle>;
};
let MindTestShare = function () {
  return <Bundle load={() => import('./pages/MindTest/MindTest')}>
    {(Com) => <Com />}
  </Bundle>;
};
let MindTestContent = function () {
  return <Bundle load={() => import('./pages/MindTest/MindTestContent')}>
    {(Com) => <Com />}
  </Bundle>;
};
let voiceMind = function () {
  return <Bundle load={() => import('./pages/Meditation/voiceMind')}>
    {(Com) => <Com />}
  </Bundle>;
};
let VideoShare = function () {
  return <Bundle load={() => import('./pages/Share/VideoShare')}>
    {(Com) => <Com />}
  </Bundle>;
};
let DshShare = function () {
  return <Bundle load={() => import('./pages/Share/DshShare')}>
    {(Com) => <Com />}
  </Bundle>;
};
let CourseH5 = function () {
  return <Bundle load={() => import('./pages/Share/CourseH5')}>
    {(Com) => <Com />}
  </Bundle>;
};
let EneryShare = function () {
  return <Bundle load={() => import('./pages/Share/EneryShare')}>
    {(Com) => <Com />}
  </Bundle>;
};
let individuationTest = function () {
  return <Bundle load={() => import('./pages/MindTest/individuation/individuationTest')}>
    {(Com) => <Com />}
  </Bundle>;
};
let individuateTestKnowH5 = function () {
  return <Bundle load={() => import('./pages/MindTest/individuation/individuateTestKnow')}>
    {(Com) => <Com />}
  </Bundle>;
};
let individuateTestConH5 = function () {
  return <Bundle load={() => import('./pages/MindTest/individuation/individuateTestCon')}>
    {(Com) => <Com />}
  </Bundle>;
};
let Pay = function () {
  return <Bundle load={() => import('./pages/courseLearn/Pay')}>
    {(Com) => <Com />}
  </Bundle>;
};
let CourseVideo = function () {
  return <Bundle load={() => import('./pages/courseLearn/CourseVideo')}>
    {(Com) => <Com />}
  </Bundle>;
};
let CourseExam = function () {
  return <Bundle load={() => import('./pages/courseLearn/CourseExam')}>
    {(Com) => <Com />}
  </Bundle>;
};
let CourseResult = function () {
  return <Bundle load={() => import('./pages/courseLearn/CourseResult')}>
    {(Com) => <Com />}
  </Bundle>;
};
function App(props) {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/PsyTestResult" component={PsyTestResultPage} />
          <Route path="/ExpertYuYue" component={ExpertYuYue} />
          <Route path="/HelpPage" component={HelpPage} />
          <Route path="/Privacy" component={Privacy} />
          <Route path="/UserMain" component={UserMain} />
          <Route path="/ZhiQingAgree" component={ZhiQingAgree} />
          <Route path="/PsyTestLast" component={PsyTestLastPage} />
          <Route path="/ZhuXiao" component={ZhuXiao} />
          <Route path="/TribeText" component={TribeText} />
          <Route path="/VipText" component={VipText} />
          <Route path="/VipAgainText" component={VipAgainText} />
          <Route path="/VideoH5" component={VideoH5} />
          <Route path="/SanFang" component={SanFang} />
          <Route path="/CourseShare" component={CourseShare} />
          <Route path="/postShare" component={postShare} />
          <Route path="/EAPXjResult" component={EAPXjResult} />
          <Route path="/EAPXjLastResult" component={EAPXjLastResult} />
          <Route path="/voiceMind" component={voiceMind} />
          <Route path="/VideoShare" component={VideoShare} />
          <Route path="/MindTestShare" component={MindTestShare} />
          <Route path="/MindTestContent" component={MindTestContent} />
          <Route path="/individuationTest" component={individuationTest} />
          <Route path="/individuateTestKnow/:id" component={individuateTestKnowH5} />
          <Route path="/individuateTestCon/:id" component={individuateTestConH5} />
          <Route path="/dshShare" component={DshShare} />
          <Route path="/EneryShare" component={EneryShare} />
          <Route path="/CourseH5" component={CourseH5} />
          <Route path="/Pay" component={Pay} />
          <Route path="/CourseVideo" component={CourseVideo} />
          <Route path="/CourseExam" component={CourseExam} />
          <Route path="/CourseResult" component={CourseResult} />
          {/* <Route path="/qfhpN5AkhM.txt" component={check}/> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;